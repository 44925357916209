import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import { Container, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.webp'

const drawerWidth = 240;
const navItems = [
  {
    label: "Home",
    path: "/"
  },
  {
    label: "Services",
    path: "/#services"
  },
  {
    label: "Gallery",
    path: "/#gallery"
  },
  {
    label: "About Us",
    path: "/#about"
  },
  {
    label: "Contact",
    path: "/#contact"
  },
];

function DrawerAppBar(props) {
    const theme = useTheme()
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box  sx={{ textAlign: 'center' }} onClick={handleDrawerToggle}>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton>
              <a 
                href={item.path} 
                style={{ 
                  color: theme.palette.primary.main,
                  fontSize: 20,
                  fontWeight: 550,
                  padding: "0 16px",
                  textDecoration: "none"
                }}
              >
                {item.label}
              </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex'  }}>
      <AppBar component="nav" position='fixed'>
        <Toolbar sx={{ backgroundColor: theme.palette.secondary.main, pt: 0.5 }}>
          <Container maxWidth="lg">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Link to="/">
                        <img
                            alt="Aglow"
                            src={logo}
                            style={{
                                height: "6rem",
                                width: "10rem",
                                marginLeft: -14
                            }}
                            loading="lazy"
                          />
                    </Link>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <a 
                              href={item.path} 
                              style={{ 
                                color: theme.palette.primary.main,
                                fontWeight: 550,
                                padding: "0 16px",
                                textDecoration: "none"
                             }}
                            >
                              {item.label}
                            </a>
                        ))}
                    </Box>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ display: { sm: 'none', marginRight: -14 } }}
                    >
                      <MenuIcon color="primary" fontSize='large' />
                    </IconButton>
                </Box>
            </Container>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, 
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;