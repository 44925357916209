import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import CustomLink from './ui/CustomLink';

const Footer = () => {
  const theme = useTheme()

  const Heading = ({ title }) => (
    <Typography
      sx={{ color: theme.palette.primary.main }} 
      variant="h6"
      fontWeight="bold" 
      letterSpacing={1}
    >
      {title}
    </Typography>
  )

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, pt: 5, mt: 28 }}>
      <Container maxWidth="lg">
        <Grid container >
          <Grid item xs={12} md={2.5} sx={{ my: 3 }}>
            <Heading title="Social Media" />
            <Box display="flex" flexDirection="column" gap={2} mt={3}>
              <Box>
                  <CustomLink url="https://www.instagram.com">
                    <InstagramIcon />
                    <Typography>Instagram</Typography>
                  </CustomLink>
              </Box>
              <Box>
                <CustomLink url="https://www.facebook.com">
                  <FacebookIcon />
                  <Typography>Facebook</Typography>
                </CustomLink>
              </Box>
              <Box>
                <CustomLink url="https://www.youtube.com">
                    <YouTubeIcon />
                    <Typography>Youtube</Typography>
                </CustomLink>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2.5} sx={{ my: 3 }}>
            <Heading title="Quick Links" />
            <Box display="flex" flexDirection="column" gap={2} mt={3}>
              <CustomLink url="/#services">
                <Typography>Services</Typography>
              </CustomLink>
              <CustomLink url="/#about-us">
                <Typography>About Us</Typography>
              </CustomLink>
              <CustomLink url="/#contact">
                <Typography>Contact Us</Typography>
              </CustomLink>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ my: 3 }}>
            <Heading title="Contact Us" />
            <Box display="flex" flexDirection="column" gap={2} mt={3}>
                <CustomLink url="tel:+61428355198">
                    <CallIcon />
                    <Typography>+61428355198</Typography>
                </CustomLink>
                <CustomLink url="mailto:admin@aglowservices.com.au">
                    <EmailIcon />
                    <Typography>admin@aglowservices.com.au</Typography>
                </CustomLink>
                <CustomLink url="#">
                    <LocationOnIcon />
                    <Typography>Po Box 241 Sunbury VIC 3429</Typography>
                </CustomLink>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ my: 3 }}>
          <Heading title="ABN: 48663255543" />
            <Box mt={3}>
              <Typography color="primary.main" textAlign="justify">
                Aglow Town Planning Services is a full member of the Planning Institute Australia
              </Typography>
            </Box>
          </Grid>
        </Grid>
          <Box pt={6} pb={3}>
            <Typography
              color="primary.main"
              variant="body1" 
              textAlign="center"
              // my={3}
            >
              @2024 Aglow Services. All rights reserved
            </Typography>
          </Box>
      </Container>
    </Box>
  )
}

export default Footer