import React from 'react'
import { Box } from '@mui/material';

import HeroSection from '../../components/HeroSection';
import ChatBot from '../../components/ChatBot';
import Services from '../../components/Services';
import Gallery from '../../components/Gallery';
import AboutUs from '../../components/AboutUs';
import Contact from '../../components/Contact';

const Home = () => {
  return (
    <Box style={{ minHeight: "80vh", position: "relative" }}>
      <HeroSection />
      <Services />
      <Gallery />
      <AboutUs />
      <Contact />
      {/* <ChatBot /> */}
    </Box>
  )
}

export default Home