import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'

import Heading from './ui/Heading'
import image from '../assets/images/image3.webp'
import team from '../assets/images/team.webp'

const AboutUs = () => {
  return (
    <Container sx={{ my: 12 }} id="about" maxWidth="lg">
        <Heading title="About Us" />
        <Grid container my={8}>
          <Grid item xs={12} md={6} mb={3}>
            <Box mx={{ xs: 1, md: 3}}>
              <img 
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 8
                }}
                src={image}
                alt="About us"
                loading="lazy"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} mb={3}>
            <Box mx={{ xs: 1, md: 3}}>
              <Typography textAlign="justify" gutterBottom>
                Welcome to Aglow Town Planning Services, a company with a rich history of providing town planning and development consultancy services for nearly two decades. As your trusted town planning consultants, we are well-versed in guiding you through the complex planning permit process. Our team, led by an experienced town planner with over 17 years of local government experience and a Full Member of the Planning Institute Australia, boasts an exceptional understanding of town planning policies, council planning application processes, and statutory requirements. We are ready to work with the council to ensure your project is handled efficiently.
              </Typography>
              <Typography textAlign="justify" gutterBottom>
                Our journey began with a passion for assisting our clients by holding their hands and helping them navigate complex town planning systems. Over the years, we have honed our skills to become industry leaders, offering a comprehensive suite of services that cater to diverse client requirements. At Aglow Town Planning Services, we understand the complexities and challenges inherent in every development project. Our approach is deeply rooted in collaboration, innovation, and a profound commitment to delivering results that exceed expectations. Whether you're a private developer, mum and dad developer, government entity, or community organization, we tailor our solutions to your unique goals and aspirations. Our unique skills, experience in the intricacies of development assessment, and a team of seasoned professionals set us apart. We bring together diverse talents to provide holistic insights and innovative solutions. This collaborative ethos enables us to confidently tackle complex projects, delivering outcomes that stand the test of time.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container my={8}>
          <Grid item xs={12} md={6} mb={3} order={{ xs: 2, md: 1 }}>
            <Box mx={{ xs: 1, md: 3}}>
              <Typography textAlign="justify" gutterBottom>
                Aglow Town Planning Services provides town planning, development approval, and project management services. We offer a comprehensive range of services that cover the entire development lifecycle. Our services include feasibility studies, concept design, pre-application, consultant management, town planning report preparation, planning application documentation, submission, and approval process management. We collaborate closely with a team of well-established specialist consultants, such as architects, engineers, arborists, ESD consultants, acoustic consultants, and heritage advisers.   
              </Typography>
              <Typography textAlign="justify" gutterBottom>
                Aglow Town Planning Services offers expert advice and assistance with various planning issues, such as residential, industrial, rural, commercial, and industrial land use. Our team is highly skilled in development applications and subdivisions subject to the Precinct Structure Plan (PSP) in Growth Areas.   
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} mb={3} order={{ xs: 1, md: 2 }}>
            <Box mx={{ xs: 1, md: 3}}>
              <img 
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 8
                }}
                src={team}
                alt="About us"
                loading="lazy"
              />
            </Box>
          </Grid>
        </Grid>
    </Container>
  )
}

export default AboutUs