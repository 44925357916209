import React from 'react'
import Typewriter from 'typewriter-effect';
import { Box, Typography, useTheme } from '@mui/material';

import bannerImg from "../assets/images/image1.webp"
import { SIZES } from '../constants/theme';

const HeroSection = () => {
  const theme = useTheme()
  return (
    <Box 
      sx={{
        background: `url(${bannerImg}) center center/cover no-repeat`,
        height: "640px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "inset 0 0 0 1000px rgba(8, 22, 39, 0.45)",
        objectFit: "contain",
        marginTop: "82px",
      }}
    >
        <Typography
          fontSize={{
            md: SIZES.medium,
            xs: SIZES.small,
          }} 
          fontWeight="bold" 
          color="secondary.main"
          textAlign="center"
          gutterBottom
        >
          Aglow Town Planning Services
        </Typography>
        <Box
          sx={{
            textAlign: "center",
            color: theme.palette.secondary.main,
            fontSize: {
              md: SIZES.large,
              xs: SIZES.medium,
            },
            fontWeight: "bold"
          }}
        >
          <Typewriter
            options={{
              strings: [
                'Residential',
                'Industrial',
                'Commercial',
                'Rural & Green Wedge Zone'
              ],
              autoStart: true,
              loop: true,
              cursor: ""
            }}
          />
          Development
        </Box>
    </Box>
  )
}

export default HeroSection