import { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import Home from './pages/home/Home';
import ChatBot from './components/ChatBot';
import Navbar from './components/Navbar'
import Footer from './components/Footer'

function App() {
  const [showNavbar, setShowNavbar] = useState(true)

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: '#00387d'
      },
      secondary: {
        main: '#EFFFFB',
        dark: "#5ce1e6",
      }
    },
    typography: {
      disabled: {
        color: "#000" 
      },
      button: {
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "normal",
        letterSpacing: 1
      }     
    } 
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {showNavbar 
        ? <Navbar />
        : null
      }
      <Home />
      <ChatBot setShowNavbar={setShowNavbar} />
      <Footer />
    </ThemeProvider>
  )
}

export default App
