import { Typography } from '@mui/material';
import React from 'react'

const Heading = ({ title }) => {
  return (
    <Typography variant='h4' color="secondary.dark" textAlign="center" fontWeight="bold" gutterBottom>
        {title}
    </Typography>
  )
}

export default Heading