import { useTheme } from '@mui/material'

const CustomLink = ({ url, color, children }) => {
  const theme = useTheme()

    return (
      <a 
        href={url}
        style={{ 
          textDecoration: "none", 
          display: "flex",
          gap: 12,
          color: color ? color : theme.palette.primary.main,
          margin: "2px 0"
        }}
      >
        {children}
      </a> 
    )
}


export default CustomLink