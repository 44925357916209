import React, { useState } from 'react'
import { Box, TextField, Button, InputAdornment, Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import emailJs from '@emailjs/browser'

const ContactForm = () => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const sendEmail = async (e) => {
        e.preventDefault()

        if (!(name && email && phone && message)) {
            alert("Please fill all required fields.")
            return 
        }

        try {
            setLoading(true)
           const res = await emailJs.send(
                process.env.REACT_APP_SERVICE_ID, 
                process.env.REACT_APP_TEMPLATE_1_ID,
                {
                    user_name: name,
                    user_email: email,
                    user_phone: phone,
                    user_message: message
                },
                process.env.REACT_APP_PUBLIC_KEY
            )
            if(res?.status === 200) {
                setSuccess(true)
                setName("")
                setEmail("")
                setPhone("")
                setMessage("")
            }
        } catch (err) {
            console.error({err})
            alert("Something went wrong! Please try again later.")
        } finally {
            setLoading(false)
        }
    }

  return (
    <Box 
        component="form"
        onSubmit={sendEmail}
        display="flex"
        flexDirection="column"
        gap={2}              
        bgcolor="white"
        borderRadius={2}
        p={{ xs: 2.5, md: 4 }}
        m={{ xs: 3, md: 6 }}
    >
        {success
            ? 
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Thanks for your message. We'll get back to you shortly.
                </Alert>
            : null
        }
        <TextField 
            label=""
            placeholder="Name"
            inputProps={{
                maxLength: 28
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
        />
        <TextField 
            label=""
            placeholder="Email"
            type="email"
            inputProps={{
                maxLength: 54
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            fullWidth
        />
        <TextField 
            label=""
            placeholder="Phone"
            type="tel"
            inputProps={{
                inputMode: 'numeric', 
                pattern: '^(0|[0-9][0-9]*)$',
                minLength: 10,
                maxLength: 10,
            }}
            InputProps={{
                startAdornment: <InputAdornment position="start">+61</InputAdornment>,
              }}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
        />
        <TextField 
            label=""
            placeholder='Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            inputProps={{
                maxLength: 300
            }}
            multiline
            rows={3}
            required
            fullWidth   
        />
        <Button 
            sx={{
                borderRadius: 8,
                mt: 1.5
            }}
            aria-label="This is aria label"
            variant="contained"
            size="large"
            disabled={loading}
            type="submit"
            
        >
           {loading ? "Sending..." : "Send Message" }
        </Button>
    </Box>
  )
}

export default ContactForm