import React from 'react'
import { Box, Card, Typography, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ServiceCard = ({ service }) => {
    const theme = useTheme()
  return (
    <Card sx={{ mx: 2, my: 3, p: 3, height: 502 }}>
        <Box height={62}>
            <Typography color="primary.main" textAlign="center" variant="h6" fontWeight="bold" >
                {service?.heading}
            </Typography>
        </Box>
        <Box sx={{ height: 6, backgroundColor: theme.palette.secondary.dark, borderRadius: 6, my: 2 }} />

        {/* Items */}
        <Box mt={4}>
            {service?.items?.map((item, index) => (
                <Box key={index} display="flex" mb={1.5} gap={1}>
                    <CheckCircleIcon fontSize='small' sx={{ color: theme.palette.secondary.dark }} />
                    <Typography>{item}</Typography>
                </Box>
            ))}
        </Box>
    </Card>
  )
}

export default ServiceCard