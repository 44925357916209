import React from 'react'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'

import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import CallIcon from '@mui/icons-material/Call'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import Heading from './ui/Heading'
import ContactForm from './ContactForm'

import CustomLink from './ui/CustomLink';
import { SIZES } from '../constants/theme';

const Contact = () => {
  const theme = useTheme()
  return (
    <Container sx={{ my: 12 }} id="contact" maxWidth="lg">
        <Heading title="Contact Us" />
        <Grid 
          sx={{ 
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
          }} 
          container 
          my={8}
          p={{ xs: 0, md: 3 }}
        >
          <Grid item xs={12} md={6} >

            <Box m={{ xs: 3, md: 6 }} display="flex" flexDirection="column" gap={4}>
              <Box>
                <Typography 
                  fontSize= {{
                    md: SIZES.medium,
                    xs: SIZES.small,
                  }}
                  variant="h4" 
                  color="secondary.dark" 
                  fontWeight="bold" 
                  mb={4}
                >
                  Let us help you with your development approval and planning permits!
                </Typography>
                <Typography  color="secondary.main" >
                  Our skilled professionals are here to help you every step of the way. Making your life better is our priority. 
                </Typography>
              </Box>

              {/* Contact details */}
              <Box display="flex" flexDirection="column" gap={1.5}>
                  <CustomLink url="tel:+61428355198" color="#FFF">
                      <CallIcon />
                      <Typography>+61428355198</Typography>
                  </CustomLink>
                  <CustomLink url="mailto:admin@aglowservices.com.au" color="#FFF">
                      <EmailIcon />
                      <Typography>admin@aglowservices.com.au</Typography>
                  </CustomLink>
                  <CustomLink url="#" color="#FFF">
                      <LocationOnIcon />
                      <Typography>Po Box 241 Sunbury VIC 3429</Typography>
                  </CustomLink>
              </Box>

              {/* Social Media */}
              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <Box>
                    <CustomLink url="https://www.instagram.com" color="#FFF">
                      <InstagramIcon sx={{ fontSize: 32 }} />
                    </CustomLink>
                </Box>
                <Box>
                  <CustomLink url="https://www.facebook.com" color="#FFF">
                    <FacebookIcon sx={{ fontSize: 32 }} />
                  </CustomLink>
                </Box>
                <Box>
                  <CustomLink url="https://www.youtube.com" color="#FFF">
                      <YouTubeIcon sx={{ fontSize: 32 }} />
                  </CustomLink>
                </Box>
              </Box> 
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm />
          </Grid>
        </Grid>
    </Container>
  )
}

export default Contact