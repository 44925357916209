import React from 'react'
import Heading from './ui/Heading';
import { Box, Container, Grid } from '@mui/material';

import multiStorey from '../assets/images/multi-storey.webp'
import office from '../assets/images/office.webp'
import parkingLot from '../assets/images/parking-lot.webp'
import town from '../assets/images/town.webp'
import warehouse from '../assets/images/warehouse.webp'
import residential from '../assets/images/residential.webp'

// const images = [
//   {
//     src: residential,
//     name: "Residential"
//   },
//   {
//     src: office,
//     name: "Office"
//   },
//   {
//     src: town,
//     name: "Town"
//   },
//   {
//     src: warehouse,
//     name: "Warehouse"
//   },
//   {
//     src: parkingLot,
//     name: "Parking lot"
//   },
//   {
//     src: multiStorey,
//     name: "Multi storey"
//   }
// ]

const Gallery = () => {
  return (
    <Container sx={{ my: 12 }} id="gallery" maxWidth="lg">
        <Heading title="Gallery" />
        <Grid container my={6}>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={residential}
                  alt="Residential"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={office}
                  alt="Office"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={town}
                  alt="Town"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={warehouse}
                  alt="Warehouse"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={parkingLot}
                  alt="Parking Lot"
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box m={1}>
                <img 
                  style={{
                    height: 220,
                    width: "100%",
                    borderRadius: 8
                  }}
                  src={multiStorey}
                  alt="Multi-storey"
                  loading="lazy"
                />
              </Box>
            </Grid>
        </Grid>
    </Container>
  )
}

export default Gallery