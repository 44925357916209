import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import emailJs from '@emailjs/browser'

import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

import icon from '../assets/images/icon.webp'

const CustomChatBot = ({ setShowNavbar }) => {
  const theme = useTheme()

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpened(true)
      if(matchesSM) 
        setShowNavbar(false)
      }, 1500)
  }, [])

  // all available props
const chatbotTheme = {
  background: '#f5f8fb',
  headerBgColor: theme.palette.secondary.dark,
  headerFontColor: '#fff',
  headerFontSize: '18px',
  botBubbleColor: theme.palette.secondary.dark,
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

  const handleToggle = ({opened}) => {
    // console.log({e})
    setOpened(opened)
    if (opened && !matchesMD) 
      setShowNavbar(false)
    else 
      setShowNavbar(true)
  }

  const handleEnd = async ({ steps, values }) => {
    const { name, phone, email, service, service_details } = steps;

    try {
      const res = await emailJs.send(
            process.env.REACT_APP_SERVICE_ID, 
            process.env.REACT_APP_TEMPLATE_2_ID, 
            {
                user_name: name?.value || "",
                user_email: email?.value || "",
                user_phone: phone?.value || "",
                service: service?.value || "",
                service_details: service_details?.value || "",
            },
            process.env.REACT_APP_PUBLIC_KEY
          )
    } catch (err) {
        console.error({err})
        alert("Something went wrong! Please try again later.")
    } 
  };


  const steps = [
    {
      id: '1',
      message: '🖐️ Hey there! Welcome to Aglow Town Planning Services.',
      trigger: 'collect-name',
      delay: 2000
    },
    {
      id: 'collect-name',
      message: 'May I have your name?',
      trigger: 'name',
      delay: 1000
    },
    {
      id: 'name',
      user: true,
      validator: (value) => {
        if (!value) {
          return 'Please enter your name.';
        }
        return true;
      },
      trigger: 'greeting-message',
    },
    {
      id: 'greeting-message',
      message: 'Hi {previousValue}, nice to meet you!',
      trigger: 'collect-service',
      delay: 1000
    },
    {
      id: 'collect-service',
      message: 'Please select the development service you are interested in?',
      trigger: 'service',
      delay: 1000
    },
    {
      id: 'service',
      options: [
        { value: 'Residential', label: 'Residential', trigger: 'collect-service-details' },
        { value: 'Industrial', label: 'Industrial', trigger: 'collect-service-details' },
        { value: 'Commercial', label: 'Commercial', trigger: 'collect-service-details' },
        { value: 'Rural & Green Wedge Zone', label: 'Rural & Green Wedge Zone', trigger: 'collect-service-details' },
        // Add more options as needed
      ],
      delay: 6000
    },
    {
      id: 'collect-service-details',
      message: 'Please provide some details about the {previousValue} services you need?',
      trigger: 'service_details', 
      delay: 1000
    },
    {
      id: 'service_details',
      user: true,
      validator: (value) => {
        if (!value) {
          return 'Please enter the details.';
        }
        return true;
      },
      trigger: '2',
    },
    {
      id: '2',
      message: 'Great!',
      trigger: 'collect-phone',
      delay: 1000
    },
    {
      id: 'collect-phone',
      message: 'Please provide your phone number.',
      trigger: 'phone', 
      delay: 1000
    },
    {
      id: 'phone',
      user: true,
      validator: (value) => {
        if (!value) {
          return 'Please enter your phone.';
        }
        return true;
      },
      trigger: 'collect-email',
      // trigger: 'end-message',
    },
    {
      id: 'collect-email',
      message: 'Please provide your email address.',
      trigger: 'email',
      delay: 1000
    },
    {
      id: 'email',
      user: true,
      validator: (value) => {
        if (!value) {
          return 'Please enter your email.';
        }
        return true;
      },
      trigger: 'end-message-1',
    },
    {
      id: 'end-message-1',
      message: 'Thanks, your information has been submitted.',
      trigger: 'end-message-2',
      delay: 1000
    },
    {
      id: 'end-message-2',
      message: 'Our experts will get back to you shortly.',
      delay: 1000,
      end: true,
    },
  ];

  return (
      <ThemeProvider theme={chatbotTheme}>
          <ChatBot 
            botAvatar={icon}
            headerTitle="Aglow Support"
            steps={steps} 
            handleEnd={handleEnd}
            handle
            floating={true}
            toggleFloating={handleToggle}
            opened={opened}
            />
      </ThemeProvider>
  )
};

export default CustomChatBot;
