import { Typography } from '@mui/material';
import React from 'react'

const SubHeading = ({ title }) => {
  return (
    <Typography variant='h6' textAlign="center">
        {title}
    </Typography>
  )
}

export default SubHeading