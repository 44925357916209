import React from 'react'
import Heading from './ui/Heading';
import { Container, Grid } from '@mui/material';
import SubHeading from './ui/SubHeading';
import ServiceCard from './ServiceCard';

const servicesList = [
  {
    heading: "Residential",
    items: [
      "Unit development",
      "Residential subdivision",
      "Apartment",
      "Multi-storey development",
      "Community care accommodation (NDIS)",
      "Residential aged care facility"
    ]
  },
  {
    heading: "Industrial",
    items: [
      "Industrial development",
      "Warehouse",
      "Change of use",
      "Car parking",
      "Freezing and cool storage",
      "Industrial subdivision",
      "Signage",
      "Depot",
      "Place of assembly"
    ]
  },
  {
    heading: "Commercial",
    items: [
      "Medical Centre",
      "Child care centre",
      "Shopping centre",
      "Food and drink premises",
      "Retail",
      "Office"
    ]
  },
  {
    heading: "Rural & Green Wedge Zone",
    items: [
      "Dwelling",
      "Freezing and Cool Storage",
      "Depot",
      "Rural store",
      "Shed",
      "Horse shelter",
      "Building and works"
    ]
  },
]

const Services = () => {
  return (
    <Container sx={{ my: 12 }} id="services" maxWidth="lg">
        <Heading title="Services" />
        <SubHeading title="Aglow Town Planning offers town planning services for planning permits in various areas, including:" />
        <Grid container my={6}>
          <Grid item xs={12} md={3}>
            <ServiceCard service={servicesList[0]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard service={servicesList[1]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard service={servicesList[2]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <ServiceCard service={servicesList[3]} />
          </Grid>
        </Grid>
    </Container>
  )
}

export default Services